<template>
	<div class="container-fluid">
    <div v-if="!unityInstance" style="text-align:center">
    <v-progress-circular style="margin-left: auto; margin-right:auto;"
      :size="50"
      color="primary"
      indeterminate
      
    ></v-progress-circular>
    </div>
		<div ref="container" id="unity-container" style="text-align:center;" class="unity-desktop">
      
			<canvas
				v-if="!unityContent"
				ref="c"
				width="960px"
				height="600px"
				style="margin-left:auto; margin-right:auto;"
			></canvas>

			<div ref="loadingBar" id="unity-loading-bar">
				<div id="unity-logo"></div>
				<div id="unity-progress-bar-empty">
					<div ref="progressBarFull" id="unity-progress-bar-full"></div>
				</div>
			</div>
			<div id="unity-footer"><v-btn v-if="unityInstance" @click="fullscreen">Full Screen</v-btn></div>
			
		</div>
    <div style="padding-left:5%; padding-right:5%">
				<!--<vuenity
					:width="'600'"
					:height="'600'"
					offsetWidth="200px"
					v-if="unityContent"
					:unity-content="unityContent"
				></vuenity>-->
			</div>
			<div style="text-align:center">
				<v-btn v-if="unityContent" @click="fullscreen">Full Screen</v-btn>
			</div>
	</div>
</template>
<script>
//import Vuenity, { UnityContent } from "vuenity";
const beforeUnloadListener = (event) => {
	event.preventDefault();
	return (event.returnValue = "Are you sure you want to exit?");
};

//import Unity from "vue-unity-webgl";
//import * as loader from "@/assets/FortuneTeller/UnityBuild/UnityLoader.js"
export default {
	//components: { Unity },
	//components: { Vuenity },
	mounted() {
		console.log("mounted", this.$refs);
		this.canvas;
		this.unityContent;
		this.loaded = true;
		//var canvas = this.$refs.c;//document.querySelector("#unity-canvas");
	},
	created() {
		console.log("created");
		//this.$store.commit("setShowNav", false);
		window.addEventListener("beforeunload", beforeUnloadListener, {
			capture: true,
		});
	},
	beforeDestroy() {
		//this.$store.commit("setShowNav", true);
		window.removeEventListener("beforeunload", beforeUnloadListener, {
			capture: true,
		});
		/// window.onbeforeunload = function() { return "Your work will be lost."; };
	},
	methods: {
		fullscreen() {
			console.log("focus");
			if (this.unityInstance) {
				this.unityInstance.SetFullscreen(1);
			}
			/* if(this.unityContent){
        this.unityContent.unityInstance.SetFullscreen(1);
      }*/
		},
		loadUnity() {
      var buildUrl;
      var loaderUrl;
      var config;
      if(!this.canvas || !this.progressBarFull || !this.loadingBar){
        return false;
      }
			if (this.task === "Flowers") {
				/*let uc = new UnityContent(
					"/Flowers/UnityBuild/FlowersWebGL.json",
					"/Flowers/UnityBuild/UnityLoader.js"
				);
				window.setTimeout(() => {
					this.unityInstance = uc.unityInstance;
				}, 1000);
				return uc;*/
        buildUrl = "/Flowers/UnityBuild";
				loaderUrl = buildUrl + "/FlowersWebGL.loader.js";
        config = {
          dataUrl: buildUrl + "/FlowersWebGL.data",
          frameworkUrl: buildUrl + "/FlowersWebGL.framework.js",
          codeUrl: buildUrl + "/FlowersWebGL.wasm",
          streamingAssetsUrl: "StreamingAssets",
          companyName: "EtherealLab",
          productName: "MergedTasks",
          productVersion: "0.1",
        };
			} else if (
				this.task === "Bunnies"
			) {
				buildUrl = "/Bunnies/UnityBuild";
				loaderUrl = buildUrl + "/BunniesWebGL.loader.js";
				config = {
					dataUrl: buildUrl + "/BunniesWebGL.data",
					frameworkUrl: buildUrl + "/BunniesWebGL.framework.js",
					codeUrl: buildUrl + "/BunniesWebGL.wasm",
					streamingAssetsUrl: "StreamingAssets",
					companyName: "EtherealLab",
					productName: "MergedTasks",
					productVersion: "0.1",
				};
				/*if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
					this.container.className = "unity-mobile";
					// Avoid draining fillrate performance on mobile devices,
					// and default/override low DPI mode on mobile browsers.
					config.devicePixelRatio = 1;
				}*/

				
				//let uc = new UnityContent("/Bunnies/UnityBuild/BunniesWebGL.json", "/Bunnies/UnityBuild/BunniesWebGL.loader.js");

			}
      if(loaderUrl){
        if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
					this.container.className = "unity-mobile";
					// Avoid draining fillrate performance on mobile devices,
					// and default/override low DPI mode on mobile browsers.
					config.devicePixelRatio = 1;
				}
        var script = document.createElement("script");
				script.src = loaderUrl;

				script.onload = () => {
					console.log("loading unity");
					window
						.createUnityInstance(this.canvas, config, (progress) => {
							this.progressBarFull.style.width = 100 * progress + "%";
							console.log("progress", progress);
						})
						.then((unityInstance) => {
							this.unityInstance = unityInstance;
							this.loadingBar.style.display = "none";
							//fullscreenButton.onclick = () => {
							// unityInstance.SetFullscreen(1);
							//};
							console.log(unityInstance);
						})
						.catch((message) => {
							alert(message);
						});
				};
				document.body.appendChild(script);
      }
		},
	},
	props: {
		task: {
			type: String,
			required: false,
			default: "",
		},
	},
	computed: {
		canvas() {
			this.loaded;
			return this.$refs.c;
		},
		loadingBar() {
			return this.$refs.loadingBar;
		},
		progressBarFull() {
			return this.$refs.progressBarFull;
		},
    container(){
      return this.$refs.container;
    },
		unityContent() {
			this.loaded;
			return this.loadUnity();
		},
	},
	data() {
		return {
			//task: "Flowers",
			unityInstance: false,
			loaded: false,
			version: "0.4",
			// unityContent: new UnityContent(
			//   "/Flowers/UnityBuild/FlowersWebGL.json", //||http://localhost:8080/file.json",
			//   "/Flowers/UnityBuild/UnityLoader.js" //||http://localhost:1000/UnityLoader.js"
			// ),
		};
	},
};
</script>
<style scoped>
#canvas {
	max-height: 600px;
	height: 600px;
}
body { padding: 0; margin: 0 }
#unity-container { position: absolute }
#unity-container.unity-desktop { left: 50%; top: 50%; transform: translate(-50%, -50%) }
#unity-container.unity-mobile { width: 100%; height: 100% }
#unity-canvas { background: #231F20 }
.unity-mobile #unity-canvas { width: 100%; height: 100% }
#unity-loading-bar { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); display: none }
#unity-logo { width: 154px; height: 130px; background: url('/Flowers/TemplateData/unity-logo-dark.png') no-repeat center }
#unity-progress-bar-empty { width: 141px; height: 18px; margin-top: 10px; background: url('/Flowers/TemplateData/progress-bar-empty-dark.png') no-repeat center }
#unity-progress-bar-full { width: 0%; height: 18px; margin-top: 10px; background: url('/Flowers/TemplateData/progress-bar-full-dark.png') no-repeat center }
#unity-footer { position: relative }

#unity-webgl-logo { float:left; width: 204px; height: 38px; background: url('/Flowers/TemplateData/webgl-logo.png') no-repeat center }
#unity-build-title { float: right; margin-right: 10px; line-height: 38px; font-family: arial; font-size: 18px }
#unity-fullscreen-button { float: right; width: 38px; height: 38px; background: url('/Flowers/TemplateData/fullscreen-button.png') no-repeat center }
#unity-mobile-warning { position: absolute; left: 50%; top: 5%; transform: translate(-50%); background: white; padding: 10px; display: none }
</style>
